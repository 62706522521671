import { Fragment, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ErrorOutline as ErrorOutlineIcon, LockOpen as LockOpenIcon, Send as SendIcon } from '@mui/icons-material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';
import { ResetMemberRequest } from '@one/api-models/lib/Admin/Members/Request/ResetMemberRequest';
import { SendActivationRequest } from '@one/api-models/lib/Admin/Members/Request/SendActivationRequest';
import { ToggleSuspensionRequest } from '@one/api-models/lib/Admin/Members/Request/ToggleSuspensionRequest';
import { ResetMemberResponse } from '@one/api-models/lib/Admin/Members/Response/ResetMemberResponse';
import { SendActivationResponse } from '@one/api-models/lib/Admin/Members/Response/SendActivationResponse';
import { ToggleSuspensionResponse } from '@one/api-models/lib/Admin/Members/Response/ToggleSuspensionResponse';

import { ApiError } from 'apiAccess/api-client';
import { ConfirmationDialog } from 'components/_common/ConfirmationDialog';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { profileCtaStyle } from './styles';

interface Props {
  memberKey: string;
  memberStatus: MemberStatus;
  allowSendActivation: boolean;
  allowResetPassword: boolean;
  testId: string;
}

export const ServiceMenu = ({ memberKey, memberStatus, allowSendActivation, allowResetPassword, testId }: Props) => {
  const { showMessage, apiErrorHandler } = useToastMessage();
  const { api } = useApiHelpers();
  const activeBrand = useSelector(selectActiveBrand);
  const navigate = useNavigate();

  const [resetConfirmationOpen, setResetConfirmationOpen] = useState<boolean>(false);
  const [suspendConfirmationOpen, setSuspendConfirmationOpen] = useState<boolean>(false);
  const [activateConfirmationOpen, setActivateConfirmationOpen] = useState<boolean>(false);

  const handleResetPasswordClick = () => {
    setResetConfirmationOpen(true);
  };

  const resetMutation = useMutation<ResetMemberResponse, ApiError, ResetMemberRequest, unknown>(
    async (request) => {
      return await api.members.reset(request);
    },
    {
      onSuccess: () => {
        showMessage('Password successfully reset', 'success');
      },
      onError: apiErrorHandler,
      onSettled: () => {
        setResetConfirmationOpen(false);
      },
    },
  );

  const handleResetPasswordAction = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      /** @todo integrate API */
      resetMutation.mutate({ brandKey: activeBrand?.key ?? '', memberKey });
    } else {
      setResetConfirmationOpen(false);
    }
  };

  const sendActivationMutation = useMutation<SendActivationResponse, ApiError, SendActivationRequest, unknown>(
    async (request) => {
      return await api.members.sendActivation(request);
    },
    {
      onSuccess: () => {
        showMessage('Activation email sent', 'success');
      },
      onError: apiErrorHandler,
    },
  );

  const suspendAccountMutation = useMutation<ToggleSuspensionResponse, ApiError, ToggleSuspensionRequest, unknown>(
    async (request) => {
      return await api.members.toggleSuspension(request);
    },
    {
      onSuccess: () => {
        showMessage(memberStatus === MemberStatus.Active ? 'Account suspended.' : 'Account activated.', 'success');
        setSuspendConfirmationOpen(false);
        setActivateConfirmationOpen(false);
        navigate(`/customers/${memberKey}`, { replace: true, state: { refetch: true } });
      },
      onError: apiErrorHandler,
    },
  );

  const handleSendActivationClick = () => {
    sendActivationMutation.mutate({ memberKey });
  };

  const handleToggleSuspendClick = (actionConfirmed: boolean, activate: boolean) => {
    if (actionConfirmed) {
      const request: ToggleSuspensionRequest = {
        brandKey: activeBrand?.key ?? '',
        memberKey,
        active: activate,
      };
      suspendAccountMutation.mutate(request);
    } else {
      setSuspendConfirmationOpen(false);
      setActivateConfirmationOpen(false);
    }
  };

  return (
    <Fragment>
      <Button
        variant="text"
        onClick={handleResetPasswordClick}
        startIcon={<LockOpenIcon htmlColor="black" />}
        sx={{ ...profileCtaStyle }}
        disabled={!allowResetPassword}
        data-testid={`${testId}ResetPasswordButton`}
      >
        Reset Password
      </Button>

      <LoadingButton
        variant="text"
        onClick={handleSendActivationClick}
        disabled={!allowSendActivation}
        startIcon={sendActivationMutation.isLoading ? null : <SendIcon htmlColor="black" />}
        sx={{ ...profileCtaStyle }}
        loading={sendActivationMutation.isLoading}
        loadingPosition="center"
        data-testid={`${testId}SendActivationButton`}
      >
        Send activation email
      </LoadingButton>

      {memberStatus === MemberStatus.Suspended ? (
        <Button
          variant="text"
          onClick={() => setActivateConfirmationOpen(true)}
          startIcon={<HowToRegIcon htmlColor="black" />}
          sx={{ ...profileCtaStyle }}
          data-testid={`${testId}ActivateButton`}
        >
          Activate
        </Button>
      ) : (
        <Button
          variant="text"
          onClick={() => setSuspendConfirmationOpen(true)}
          startIcon={<ErrorOutlineIcon htmlColor="black" />}
          sx={{ ...profileCtaStyle }}
          data-testid={`${testId}SuspendButton`}
        >
          Suspend
        </Button>
      )}

      <ConfirmationDialog
        open={resetConfirmationOpen}
        isLoading={resetMutation.isLoading}
        title="Reset password"
        question="This action will reset the password of the member and send them an email to set a new password."
        confirmButton="Reset"
        abortButton="Cancel"
        onClose={handleResetPasswordAction}
        testId={`${testId}ResetPasswordDialog`}
      />

      <ConfirmationDialog
        open={activateConfirmationOpen}
        isLoading={suspendAccountMutation.isLoading}
        title="Activate account"
        question="By activating this account, the user will regain access to log in and make purchases."
        confirmButton="Activate"
        abortButton="Cancel"
        onClose={(isConfirmed) => handleToggleSuspendClick(isConfirmed, true)}
        testId={`${testId}ActivateAccountDialog`}
      />

      <ConfirmationDialog
        open={suspendConfirmationOpen}
        isLoading={suspendAccountMutation.isLoading}
        title="Suspend account"
        question="Suspending this account will prevent the user from logging in or making any future purchases."
        confirmButton="Suspend"
        abortButton="Cancel"
        onClose={(isConfirmed) => handleToggleSuspendClick(isConfirmed, false)}
        testId={`${testId}SuspendAccountDialog`}
      />
    </Fragment>
  );
};
